<template>
  <footer class="contacts-footer">
    <div class="contacts">
      <p>Способы оплаты: карта Сбербанка;</p>
      <p>Условия оплаты: предоплата - 100%.При оплате на карту - поле "Назначение платежа" оставьте пустым.</p>
      <p>На нашем сайте цена указана за 100 грамм пряжи. </p>
      <p>Оплата заказа осуществляется после выставления счета по электронной почте. При оплате уточните о наличии вашей забронированной пряжи на складе. </p>
      <p>Оплата производится не позднее 3-х дней со дня выставления счета, затем заказ расформировывается без предупреждения клиента.</p>
      <p>Отправка заказа : не позднее следующего рабочего дня после подтверждения оплаты.</p>
      <p>Отправка возможна по России почтой или СДЭК.</p>
      <p>При отправке почтой, СДЭК в стоимость заказа включается стоимость упаковки, доставки. Страховка при отправке почтой  рассчитывается только по желанию клиента, указанному в комментариях при заказе на сайте или при личной переписке в социальных сетях. Продавец не отвечает за работу и сроки доставки почты,СДЭК.</p>
      <p>Продавец оставляет за собой право аннулировать заказ без предупреждения, если покупатель не отвечает на запросы в течении суток со дня оформленного заказа. </p>
      <p>Оплаченный товар обмену и возврату не подлежит. Делайте свой выбор осознанно! </p>
      <p>Допускается несоответствие веса в граммах - до 10 г. (пряжа имеет свойство впитывать влагу и усыхать, мы не можем проследить в каких условиях проходит ее перевозка до покупателя).</p>
      <p>Вы покупаете СТОК, обрыв нити в бобинах от 1 до 3-х, незначительная запыленность не является браком. Мы всегда вычитаем вес конуса из бобины (обращаем Ваше внимание, при расчете оплаты за доставку вес конуса учитывается!).</p>
    </div>
  </footer>
</template>
  
<script>
export default {
  name: "delivery-options",
};
</script>

<style>
.contacts-footer {
  margin: 50px;
  background-color: white;
  border-radius: 5px;
  padding: 3em;
}

.contacts-header {
  text-align: center;
}

.contacts {
  width: 850px;
  margin: auto;
}
</style>
  